
import { Component } from 'vue-property-decorator'
import CharacterBio from '@/components/character_bio.vue'
import TeamBio from '@/components/team/bio.vue'
import { Character } from '@/interfaces/character'
import Team from '@/interfaces/team'
import SavageAimMixin from '@/mixins/savage_aim_mixin'

@Component({
  components: {
    CharacterBio,
    TeamBio,
  },
})
export default class Dashboard extends SavageAimMixin {
  get characters(): Character[] {
    return this.$store.state.characters
  }

  get teams(): Team[] {
    return this.$store.state.teams
  }

  mounted(): void {
    this.$store.dispatch('fetchCharacters')
    this.$store.dispatch('fetchTeams')
  }
}
