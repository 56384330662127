
import { Component, Prop, Watch } from 'vue-property-decorator'
import Dashboard from '@/components/dashboard.vue'
import Welcome from '@/components/welcome.vue'
import SavageAimMixin from '@/mixins/savage_aim_mixin'

@Component({
  components: {
    Dashboard,
    Welcome,
  },
})
export default class Auth extends SavageAimMixin {
  @Prop({ default: '' })
  next!: string

  @Prop({ default: false })
  redirect!: boolean

  get buttonUrl(): string {
    if (this.next === '') {
      return this.LOGIN_URL
    }
    return `${this.LOGIN_URL}?next=${this.next}`
  }

  @Watch('$store.state.user.id')
  checkAuth(): void {
    if (this.authenticated) {
      this.$router.push('/')
    }
  }

  mounted(): void {
    // If the user is authenticated, we don't need to be here
    this.checkAuth()
    document.title = 'Login - Savage Aim'
  }

  // Handlers for the other potential error messages
  get wasCancelled(): boolean {
    return this.$route.query.auth_cancelled === '1'
  }

  get wasError(): boolean {
    return this.$route.query.auth_error === '1'
  }
}
