var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('footer',{staticClass:"content has-text-centered",attrs:{"id":"footer"}},[_vm._m(0),_vm._m(1),_vm._m(2),_c('a',{attrs:{"data-microtip-position":"top","role":"tooltip","aria-label":"Changelog"},on:{"click":_vm.showChangelog}},[_vm._m(3)]),_vm._m(4),_vm._m(5),_vm._m(6),_c('p',[_vm._v("Savage Aim release "+_vm._s(_vm.$store.state.version)+", by Eira Erikawa (Lich)")]),_c('p',[_vm._v("FINAL FANTASY XIV ©2010 - "+_vm._s(_vm.currentYear)+" SQUARE ENIX CO., LTD. All Rights Reserved.")]),_c('p',[_vm._v("FINAL FANTASY is a registered trademark of Square Enix Holdings Co., Ltd. All material used under license.")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{staticClass:"kofi-link",attrs:{"href":"https://ko-fi.com/eriyukidesu","target":"_blank","data-microtip-position":"top","role":"tooltip","aria-label":"Donate on Ko-Fi"}},[_c('span',{staticClass:"icon is-large"},[_c('div',{staticClass:"icon-stack"},[_c('img',{staticClass:"image is-24x24 cup",attrs:{"src":"/kofi-cup.svg","alt":"Ko-Fi Logo","height":"24","width":"24"}}),_c('img',{staticClass:"image is-24x24 heart",attrs:{"src":"/kofi-heart.svg","alt":"Ko-Fi Logo","height":"24","width":"24"}})])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{attrs:{"href":"/backend/schema","target":"_blank","data-microtip-position":"top","role":"tooltip","aria-label":"API Schema"}},[_c('span',{staticClass:"icon is-large"},[_c('i',{staticClass:"material-icons"},[_vm._v("api")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{attrs:{"href":"https://wiki.savageaim.com","target":"_blank","data-microtip-position":"top","role":"tooltip","aria-label":"Wiki"}},[_c('span',{staticClass:"icon is-large"},[_c('i',{staticClass:"material-icons"},[_vm._v("menu_book")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',{staticClass:"icon is-large"},[_c('i',{staticClass:"material-icons"},[_vm._v("update")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{attrs:{"href":"https://github.com/SavageAim/app","target":"_blank","data-microtip-position":"top","role":"tooltip","aria-label":"Github Repo"}},[_c('span',{staticClass:"icon is-large"},[_c('i',{staticClass:"material-icons"},[_vm._v("code")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{attrs:{"href":"https://github.com/SavageAim/plugin","target":"_blank","data-microtip-position":"top","role":"tooltip","aria-label":"Dalamud Plugin"}},[_c('span',{staticClass:"icon is-large"},[_c('i',{staticClass:"material-icons"},[_vm._v("extension")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a',{staticClass:"discord-link",attrs:{"href":"https://discord.gg/k8szJ5qAKw","target":"_blank","data-microtip-position":"top","role":"tooltip","aria-label":"Savage Aim Discord"}},[_c('span',{staticClass:"icon is-large"},[_c('img',{staticClass:"image is-24x24 discord",attrs:{"src":"/discord.svg","alt":"Discord Logo","height":"24","width":"24"}})])])
}]

export { render, staticRenderFns }