
import { Component, Prop, Vue } from 'vue-property-decorator'
import Team from '@/interfaces/team'
import TeamMember from '@/interfaces/team_member'

@Component
export default class TeamBio extends Vue {
  @Prop({ default: true })
  displayIcons!: boolean

  @Prop()
  team!: Team

  // Check if the requesting user is the leader of the given team
  isLead(): boolean {
    // Check for the member that is owned by the requesting user and return the value of the lead property
    return this.team.members.find((teamMember: TeamMember) => teamMember.character.user_id === this.$store.state.user.id)?.lead ?? false
  }
}
