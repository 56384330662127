
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Welcome extends Vue {
  cls = 'danger'

  clsList = [
    'danger',
    'info',
    'primary',
    'success',
    'warning',
  ]

  get materia(): HTMLElement {
    return this.$refs.materia as HTMLElement
  }

  changeMateria(): void {
    if (this.materia.classList.contains('spin')) return
    this.materia.classList.add('spin')
    window.setTimeout(this.clsChange, 350)
    window.setTimeout(this.stopSpin, 1200)
  }

  clsChange(): void {
    // Record the current cls so we don't pick it again
    const current = this.clsList.indexOf(this.cls)
    let newIndex = current
    while (newIndex === current) {
      newIndex = Math.floor(Math.random() * this.clsList.length)
    }
    this.cls = this.clsList[newIndex]
  }

  stopSpin(): void {
    this.materia.classList.remove('spin')
  }
}
