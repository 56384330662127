
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import * as Sentry from '@sentry/vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Notification from '@/interfaces/notification'

dayjs.extend(relativeTime)

@Component
export default class NotificationCard extends Vue {
  @Prop()
  notification!: Notification

  get date(): dayjs.Dayjs {
    return dayjs(this.notification.timestamp)
  }

  get humanDate(): string {
    return this.date.fromNow()
  }

  async markAsRead(): Promise<void> {
    try {
      const response = await fetch(`/backend/api/notifications/${this.notification.id}/`, {
        method: 'POST',
        headers: {
          'X-CSRFToken': this.$cookies.get('csrftoken'),
        },
      })

      if (response.ok) {
        this.$store.dispatch('fetchNotifications')
      }
      else {
        this.$notify({ text: `Unexpected HTTP response ${response.status} received when marking notification as read.`, type: 'is-danger' })
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when marking notification as read.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }

  open(): void {
    // Open a Notification, marking it as read in the process
    this.markAsRead()

    // Open the link contained in the notification, using the router (if not already on the page)
    if (this.$route.path !== this.notification.link) this.$router.push(this.notification.link)
    this.$emit('close')
  }
}
