
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Character } from '@/interfaces/character'

@Component
export default class CharacterBio extends Vue {
  @Prop()
  character!: Character

  @Prop({ default: true })
  displayUnverified!: boolean
}
