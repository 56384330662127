
import { Component, Watch } from 'vue-property-decorator'
import Notification from '@/interfaces/notification'
import SavageAimMixin from '@/mixins/savage_aim_mixin'
import NotificationsModal from './modals/notifications.vue'

@Component
export default class Nav extends SavageAimMixin {
  // Only check for the refs after we've been mounted
  isMounted = false

  get burger(): Element {
    return this.$refs.burger as Element
  }

  get nav(): Element {
    return this.$refs.navbar as Element
  }

  get unreads(): number {
    // Filter the list of notifications, see how many are not read yet
    return this.$store.state.notifications.reduce((sum: number, notif: Notification) => sum + (notif.read ? 0 : 1), 0)
  }

  destroyed(): void {
    this.isMounted = false
  }

  toggleNavbar(): void {
    this.burger.classList.toggle('is-active')
    this.nav.classList.toggle('is-active')
  }

  async logout(): Promise<void> {
    // Send a GET request to the logout url, and that should be all we need to do
    await fetch(this.LOGOUT_URL)
    this.$store.commit('resetUser')
    if (this.$route.path !== '/') this.$router.push('/')
    this.$notify({ text: 'Successfully logged out!', type: 'is-success' })
  }

  mounted(): void {
    this.isMounted = true
  }

  showNotifs(): void {
    this.$modal.show(NotificationsModal)
  }

  // Watch the location.path value and any time it changes run the function
  @Watch('$route', { immediate: true, deep: true })
  urlChange(): void {
    if (this.isMounted) {
      this.burger.classList.remove('is-active')
      this.nav.classList.remove('is-active')
    }
  }
}
