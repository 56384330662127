
import * as Sentry from '@sentry/vue'
import { Component, Vue } from 'vue-property-decorator'
import NotificationCard from '@/components/notification_card.vue'
import Notification from '@/interfaces/notification'

@Component({
  components: {
    NotificationCard,
  },
})
export default class Notifications extends Vue {
  notifsPerPage = 4

  page = 1

  get maxPages(): number {
    return Math.ceil(this.notifications.length / this.notifsPerPage)
  }

  get notifications(): Notification[] {
    return this.$store.state.notifications
  }

  close(): void {
    this.$emit('close')
  }

  async markAllAsRead(): Promise<void> {
    try {
      const response = await fetch('/backend/api/notifications/', {
        method: 'POST',
        headers: {
          'X-CSRFToken': this.$cookies.get('csrftoken'),
        },
      })

      if (response.ok) {
        this.$store.dispatch('fetchNotifications')
      }
      else {
        this.$notify({ text: `Unexpected HTTP response ${response.status} received when marking notifications as read.`, type: 'is-danger' })
      }
    }
    catch (e) {
      this.$notify({ text: `Error ${e} when marking notifications as read.`, type: 'is-danger' })
      Sentry.captureException(e)
    }
  }

  pageRange(): ReadonlyArray<number> {
    const size = this.page * this.notifsPerPage <= this.notifications.length ? this.notifsPerPage : this.notifications.length % this.notifsPerPage
    const start = (this.page - 1) * this.notifsPerPage
    return Array.from({ length: size }, (x, i) => i + start)
  }

  viewAll(): void {
    this.$router.push('/notifications/')
    this.$emit('close')
  }
}
